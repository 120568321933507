import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useFileServiceContext } from '../context/FileServiceContext';
import { formatError } from '../utils/error';
import { FILE_QUERIES } from './file-queries';
import { FileServiceError, UploaderResponse, UploadInput, ReactQueryUseMutationOptions } from './types';

export default function useUploadFile(options?: ReactQueryUseMutationOptions<UploaderResponse, UploadInput>) {
  const { configuration, restClient } = useFileServiceContext();
  const queryClient = useQueryClient();

  const [uploadProgress, setUploadProgress] = React.useState<number>(0);

  const mutation = useMutation<UploaderResponse, FileServiceError, UploadInput>(
    async uploadData => {
      try {
        // Prepare form data for upload.
        const formData = new FormData();
        formData.append('file', uploadData.file);
        formData.append('organizationId', configuration.organizationId);
        formData.append('fileAction', uploadData.options.fileAction); // 'download' or 'sign'

        if (uploadData.options.metadata) formData.append('metadata', JSON.stringify(uploadData.options.metadata));

        // Override the default headers with the ones that are needed for the upload.
        // Note that it's a multipart/form-data request.
        restClient.defaults.headers.common['Content-Type'] = 'multipart/form-data';
        restClient.defaults.headers.common['Accept'] = '*/*';

        const { data } = await restClient.post(`/files/upload`, formData, {
          onUploadProgress(progressEvent: ProgressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        });

        return data;
      } catch (error) {
        setUploadProgress(0);
        throw formatError(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([FILE_QUERIES.GET_FILES]);
      },
      retry: 1, // Retry 1 times before failing.
      ...options,
    }
  );

  return {
    uploadFile: mutation.mutate,
    uploadProgress,
    ...mutation,
  };
}
