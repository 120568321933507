import {
  QueryKey,
  UseInfiniteQueryOptions,
  UseMutateFunction,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { FieldPath, WhereFilterOp, OrderByDirection } from 'firebase/firestore';

export type UploadMetadata = {
  fileAction: FileAction;
  metadata?: {
    uploadPath?: string; // If not provided, default to 'uploads'
    entityReferences?: string[]; // Array of document references to link the uploaded file to.
  };
};

export type FileServiceError = { status: number; message: string };

export type UploaderResponse = {
  id: string;
};

export type UploadInput = {
  file: File;
  options: UploadMetadata;
};

export type UseFileUploaderHook = {
  isUploading: boolean;
  uploadProgress: number;
  uploadError: AxiosError<FileServiceError, any> | null;
  uploadFile: UseMutateFunction<UploaderResponse, AxiosError<FileServiceError>, UploadInput, unknown>;
};

export type UseFileListHook = {
  getFile: (fileId: string) => UseQueryResult<FileDocument, AxiosError<FileServiceError, any>>;
  getFiles: (
    options: GetDocumentsFilter
  ) => UseQueryResult<GetDocumentsResponse<FileDocument>, AxiosError<FileServiceError, any>>;
};

export type UseFileServiceHook = UseFileUploaderHook & UseFileListHook;

// TODO types exist in ws-file-service. Find a way to publish the types from there.
export enum MalwareStatus {
  SCANNING = 'scanning',
  CLEAN = 'clean',
  INFECTED = 'infected',
  QUARANTINED = 'quarantined',
  ERROR = 'error',
}

// This wouldn't work because tsdx used older version of typescript and pretter.
// export type FileStatus = `${MalwareStatus}`;
export type FileStatus = 'scanning' | 'clean' | 'infected' | 'quarantined' | 'error';

export interface FileDocument {
  fileStatus: FileStatus;
  fileError?: string | null;
  organizationId: string;
  metadata: FileMetadata;
  storageReference: string | null;
  file: {
    mimetype: string;
    originalname: string;
    size: number;
  };
  fileAction: FileAction;
  fileURL?: string | null;
  name: string;
  scanResult?: {
    status: FileStatus;
    scannerVersion: string;
  };
  id: string;
  sharedWith?: Record<string, boolean>;
  sharedFor?: Record<string, boolean>;
  createdAt: string;
  updatedAt: string;
  createdBy: ActionBy;
  updatedBy: ActionBy;
  deletedAt: string | null; // null means not deleted
  deledBy: ActionBy | null; // null means not deleted
  archivedAt: string | null; // null means not archived
  archivedBy: ActionBy | null; // null means not archived
}

export type ActionBy = {
  uid: string;
  displayName: string;
  email: string;
};
export interface FileMetadata {
  fileRecordId: string;
  uploadPath: string;
  entityReferences?: string[];
}

export const AllowedFileActions = ['downloadable', 'e-signature'] as const;

export type FileAction = typeof AllowedFileActions[number];

export type QueryCondition = {
  field: string;
  operator: WhereFilterOp;
  value: any;
};

export type GetDocumentsFilter = {
  conditions?: QueryCondition[];
  limit?: number;
  startAt?: string;
  startAfter?: string;
  endAt?: string;
  endBefore?: string;
  orderBy?: {
    field: string | FieldPath;
    direction?: OrderByDirection;
  }[];
  hadAuditFields?: boolean;
  includeArchived?: boolean;
  includeDeleted?: boolean;
};

export type GetDocumentsResponse<T> = {
  lastDocumentId: string | null;
  hasMore: boolean;
  documents: T[];
};

export type GetFilesResponse = GetDocumentsResponse<FileDocument>;

export type ReactQueryUseQueryOptions<TData, TFnData = TData, E = FileServiceError> =
  | Omit<UseQueryOptions<TData, E, TFnData, QueryKey>, 'initialData' | 'queryFn' | 'queryKey'>
  | undefined;

export type ReactQueryUseMutationOptions<R, I, E = FileServiceError> =
  | Omit<UseMutationOptions<R, E, I, unknown>, 'mutationFn' | 'mutationKey'>
  | undefined;

export type ReactQueryUseInfiniteQueryOptions<TData, F, TFnData = TData, QData = TData, E = FileServiceError> = Omit<
  UseInfiniteQueryOptions<TFnData, E, TData, QData, [string, F]>,
  'queryKey' | 'queryFn'
>;

export interface ShareInput {
  files: string[];
  message?: string;
  users?: string[];
  students?: string[];
  locations?: string[];
  rooms?: string[];
}

export type ShareResponse = string;

export type UseDownloadFilesHook = {
  downloadFiles: (fileIds: string[]) => Promise<void>;
  isLoading: boolean;
  downloadProgress: number;
  error: FileServiceError | null;
};
