export enum FILE_QUERIES {
  GET_FILE = 'FILE_SERVICE_GET_FILE',
  GET_FILES = 'FILE_SERVICE_GET_FILES',
  UPLOAD_FILE = 'FILE_SERVICE_UPLOAD_FILE',
  DOWNLOAD_FILES = 'FILE_SERVICE_DOWNLOAD_FILES',
}

export enum SHARE_QUERIES {
  GET_SHARE = 'FILE_SERVICE_GET_SHARE',
  GET_SHARES = 'FILE_SERVICE_GET_SHARES',
}
