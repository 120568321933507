import { useMutation } from '@tanstack/react-query';
import { useFileServiceContext } from '../context/FileServiceContext';
import { formatError } from '../utils/error';
import { FileServiceError, ReactQueryUseMutationOptions, ShareInput, ShareResponse } from './types';

export default function useShareFiles(options?: ReactQueryUseMutationOptions<ShareResponse, ShareInput>) {
  const { restClient } = useFileServiceContext();

  const mutation = useMutation<ShareResponse, FileServiceError, ShareInput>(
    async shareData => {
      try {
        const { data } = await restClient.post(`/share`, shareData);

        return data;
      } catch (error) {
        throw formatError(error);
      }
    },
    {
      retry: 1, // Retry 1 times before failing.
      ...options,
    }
  );

  return {
    shareFiles: mutation.mutate,
    ...mutation,
  };
}
