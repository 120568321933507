import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import React, { createContext, useContext } from 'react';
import { getUserJWT } from '../utils/auth';
import { FileServiceConfiguration, FileServiceContextProps } from './types';

export const FileServiceContext = createContext<FileServiceContextProps>({} as FileServiceContextProps);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  logger: {
    log: console.log,
    warn: console.warn,
    // ✅ no more errors on the console for tests
    error: process.env.NODE_ENV === 'test' ? () => {} : console.error,
  },
});

export default function FileServiceProvider({
  children,
  configuration,
}: {
  children: React.ReactNode;
  configuration: FileServiceConfiguration;
}) {
  // CREATE AXIOS INSTANCE
  const axiosInstance = axios.create({
    baseURL: configuration?.fileServiceURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  // INJECT JWT TOKEN
  axiosInstance.interceptors.request.use(async config => {
    try {
      // Extract userJWT from auth service
      const userJWT = await getUserJWT(configuration.auth);

      if (userJWT && config.headers) {
        // Attach userJWT to request
        config.headers.Authorization = `Bearer ${userJWT}`;
      }
    } catch (error) {
      console.log(error);
    }
    return config;
  });

  return (
    <FileServiceContext.Provider value={{ configuration, restClient: axiosInstance, queryClient }}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </FileServiceContext.Provider>
  );
}

// Hook to expose the FileServiceContext
export function useFileServiceContext(): FileServiceContextProps {
  const context = useContext(FileServiceContext);

  if (!context.configuration) throw new Error('hook must be wrapped with FileServiceProvider');

  if (!context.configuration.fileServiceURL?.length)
    throw new Error('FileServiceProvider must include configuration.fileServiceURL');

  return context;
}
