import { useCallback, useState } from 'react';
import { useFileServiceContext } from '../context/FileServiceContext';
import { formatError } from '../utils/error';
import { UseDownloadFilesHook } from './types';

export default function useDownloadFiles(): UseDownloadFilesHook {
  const { restClient } = useFileServiceContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<UseDownloadFilesHook['error']>(null);
  const [downloadProgress, setDownloadProgress] = useState<number>(0);

  const downloadFiles = useCallback(
    async (files: string[]) => {
      try {
        if (!files?.length) throw new Error('File ids are required');
        setIsLoading(true);

        let params = new URLSearchParams();
        files.forEach(fileId => {
          params.append('fileId', fileId);
        });

        const response = await restClient.get(`/files/download`, {
          params,
          responseType: 'blob', // Important
          onDownloadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setDownloadProgress(percentCompleted);
            setIsLoading(false);
          },
        });

        // Extract the filename from the content-disposition header.
        const [, fileName = 'ws_download'] = response.headers['content-disposition'].split('filename=');

        // Create a hidden link element, and then 'click' it programmatically.
        const blobURL = window.URL.createObjectURL(new Blob([response.data]));
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;

        // Set download attribute and file name.
        tempLink.setAttribute('download', fileName);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }

        // Append to html link element page
        document.body.appendChild(tempLink);

        // Trigger the download.
        tempLink.click();

        // Removes the link from the DOM.
        // Fixes "webkit blob resource error 1"
        setTimeout(function() {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }, 200);
      } catch (error) {
        setIsLoading(false);

        // Extra error parsing is required since axios responseType is set to 'blob'.
        if (typeof (error as any)?.response?.data?.text === 'function')
          error = JSON.parse(await (error as any).response.data.text());

        setError(formatError(error));

        // Purposely not throwing the error here. Error is bubbled up to the caller via the error state.
      }
    },
    [restClient]
  );

  return {
    downloadFiles,
    isLoading,
    downloadProgress,
    error,
  };
}
