import { User } from 'firebase/auth';

/**
 * Helper function to extract the userJWT from use
 * @param user Firebase Auth user
 * @return User's JWT token
 */
export function getUserJWT(user: User | null): Promise<string> {
  try {
    if (!user) throw new Error('User is not authenticated');
    return user.getIdToken();
  } catch (error) {
    throw new Error((error as any)?.message || "Couldn't get user's JWT token");
  }
}
