import { AxiosError } from 'axios';
/**
 * Formats an error object into a standardized format.
 * @param error Thrown error
 * @returns Formatted error object
 */
export function formatError(error: any): { status: number; message: string } {
  let status = 500;
  let message = error?.message || 'Unknown error occurred';

  if (error instanceof AxiosError) {
    status = error.response?.data?.status || status;
    message = error.response?.data?.message || message;
  }

  return { status, message };
}
