import { useInfiniteQuery } from '@tanstack/react-query';
import { useFileServiceContext } from '../context/FileServiceContext';
import { formatError } from '../utils/error';
import { FILE_QUERIES } from './file-queries';
import { FileServiceError, GetDocumentsFilter, GetFilesResponse, ReactQueryUseInfiniteQueryOptions } from './types';

export default function useGetFiles(
  filters: GetDocumentsFilter = {},
  options?: ReactQueryUseInfiniteQueryOptions<GetFilesResponse, GetDocumentsFilter>
) {
  const { configuration, restClient } = useFileServiceContext();

  return useInfiniteQuery<GetFilesResponse, FileServiceError, GetFilesResponse, [string, GetDocumentsFilter]>(
    [FILE_QUERIES.GET_FILES, filters],
    async ({ pageParam, queryKey }) => {
      // Extract query filters from the query key.
      let [, queryFilter] = queryKey;

      // Add cursor if pagParams is defined.
      if (pageParam) queryFilter = { ...pageParam, ...queryFilter };

      try {
        const { data } = await restClient.post<GetFilesResponse>(
          `/files/organizations/${configuration.organizationId}`,
          {
            filters: queryFilter,
          }
        );

        return data;
      } catch (error) {
        // set the error to the query
        throw formatError(error);
      }
    },
    {
      getNextPageParam: lastPage => {
        if (!lastPage.hasMore || !lastPage.lastDocumentId) return undefined;
        return { startAfter: lastPage.lastDocumentId };
      },
      retry: 1, // Retry 1 times before failing.
      ...options,
    }
  );
}
